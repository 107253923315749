<template>
  <div class="organization_warp">
    <div class="main_top">
      
        <el-select v-if="adminid == 1"
          v-model="procuratorateid"
          placeholder="请选择检察院名称"
          @change="selectone"
          style="width: 300px;margin-right:10px"
        >
          <el-option
            v-for="item in proList"
            :key="item.id"
            :value="item.id"
            :label="item.procuratoratename"
          ></el-option>
        </el-select>
        <div>
          <el-button class="style_button" type="text" @click="handleAdd()"
            >添加</el-button
          >

          <el-dialog
            :visible.sync="dialogFormVisible"
            @close="closeDialog"
            :close-on-click-modal="false"
          >
            <div slot="title" class="dialog_title">
              <img src="../../assets/zhcpIcon/add_icon.png" alt="" />
              <span>{{ titleName }}</span>
            </div>
            <el-form :model="form">
              <el-form-item :label-width="formLabelWidth">
                <div class="form_label" slot="label" style="width:90px">
                  <span>*</span>检察院名称
                </div>
                <el-select
                  v-if="adminid == 1"
                  v-model="form.procuratorateid2"
                  placeholder="请选择检察院名称"
                  style="width: 300px; margin-right: 10px"
                >
                <el-option
                  v-for="item in proList2"
                  :key="item.id"
                  :value="item.id"
                  :label="item.procuratoratename"
                ></el-option>
                </el-select>
                <el-input v-else disabled v-model="form.name" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item :label-width="formLabelWidth">
                <div class="form_label" slot="label" style="width:90px">
                  <span>*</span>部门信息
                </div>
                <el-input v-model="form.depname" autocomplete="off"></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="dialogFormVisible = false">取 消</el-button>
              <el-button type="primary" @click="handleClick">确 定</el-button>
            </div>
          </el-dialog>
        </div>
        <!-- <div>
          <el-button
            slot="reference"
            @click="open"
            class="style_button style_button_del"
            >删除</el-button
          >
        </div> -->
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      ref="multipleTable"
      @selection-change="handleSelectionChange"
      tooltip-effect="dark"
    >
      <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
      <el-table-column label="序号" width="50">
        <template slot-scope="scope">
          <span>{{ (page - 1) * pageSize + scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="检察院名称">
        <template slot-scope="scope">
          <p>{{ scope.row.name }}</p>
        </template>
      </el-table-column>
      <el-table-column label="部门信息">
        <template slot-scope="scope">
          <p>{{ scope.row.depname }}</p>
        </template>
      </el-table-column>
      <el-table-column label="操作" class="operate" width="200">
        <template slot-scope="scope">
          <el-button
            size="mini"
            @click="handleEdit(scope.row.id, scope.row)"
            >编辑</el-button
          >
          <!-- <el-button
            size="mini"
            type="danger"
            @click="handleRemove(scope.row.id)"
            >删除</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
        hide-on-single-page
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { Message } from "element-ui";
import {
  getDepartmentByPage,
  getProInfoList,
  insertLocation,
  updateLocation,
} from "@/api/system";
export default {
  data() {
    return {
      adminid: JSON.parse(window.localStorage.getItem("tltz_userinfo")).id,
      proList:[],
      proList2:[],
      options: [],
      perList: [],
      value: [],
      List: [],
      disabled: false,
      loading: false,
      states: [],
      dialogFormVisible: false,
      form: {
        depname: "",
        procuratorateid:'',
        procuratorateid2:'',
      },
      formLabelWidth: "90px",
      userInfo:{},
      multipleSelection: [], //批量删除
      tableData: [], //部门列表
      total: 0, //总条数
      page: 1, //页数
      pageSize: 10, //每页限制
      currentPage: 1, //当前页数
      procuratorateid: null, //搜索当前项
      titleName: "", //弹出标题
    };
  },
  mounted() {
    this.typeid = JSON.parse(window.localStorage.getItem("tltz_userinfo")).types.id;
    if( this.typeid == 6){
      this.procuratorateid = -1;
    }else{
      this.procuratorateid = JSON.parse(window.localStorage.getItem("tltz_userinfo")).procuratorate.id;
      this.disabled = true
    }
    // this.procuratorateid2 = JSON.parse(window.localStorage.getItem("tltz_userinfo")).procuratorate.id;
    this.userInfo = JSON.parse(window.localStorage.getItem("tltz_userinfo"));
    // this.procuratorateid = this.userInfo.procuratorate.id;
    this.getList();
    // this.getProList();
    this.getproList111()
    this.getproList222()
    this.perList = JSON.parse(window.localStorage.getItem("permissionidList"));
    console.log(this.perList);
    console.log(this.userInfo);

  },
  methods: {
    //获取列表
    getproList111() {
      getProInfoList(this.page, this.pageSize).then((data) => {
        console.log(data,'data')
        this.proList = data.data.list;
        this.proList.unshift({
          id: -1,
          procuratoratename: '全部'
        })
      });
    },
    //获取列表
    getproList222() {
      getProInfoList(this.page, this.pageSize).then((data) => {
        console.log(data,'data')
        this.proList2 = data.data.list;
      });
    },
    selectone(){
      this.getList()
    },
    //获取列表
    getList() {
      const page = this.page;
      const pageSize = this.pageSize;
      getDepartmentByPage(page,pageSize,JSON.parse(window.localStorage.getItem("tltz_userinfo")).id,this.procuratorateid).then((data) => {
        console.log(data,'data')
        if (data.data == null) {
          this.page = 1;
          this.tableData = [];
          this.total = 0;
          this.currentPage = 1;
          return;
        } else {
          console.log(data.data)
          this.tableData = data.data.list;
          this.total = data.data.total;
          this.currentPage = data.data.pageNum;
          this.states = this.tableData;
          // this.list = this.states.map((item) => {
          //   return { value: item.departmentName };
          // });
        }
      });
    },
    //  //获取检察院列表
    // getProList() {
    //   getProInfoList(this.page).then((data) => {
    //     if (data.data == null) {
    //       return;
    //     } else {
    //       this.List = data.data.list;
    //     }
    //   });
    // },
    //添加
    handleAdd() {
      let res = this.perList.filter((val) => val == 37);
      console.log(res);
      if (!res.length) {
        Message.error("您没有这个权限");
      } else {
        this.dialogFormVisible = true;
        this.form.procuratorateid = JSON.parse(window.localStorage.getItem("tltz_userinfo")).procuratorate.id;
        this.form.depname = "";
        this.form.id = '';
        this.currentId = '';
        this.form.procuratorateid2 = "";
        this.form.name = JSON.parse(window.localStorage.getItem("tltz_userinfo")).procuratorate.procuratoratename
        this.titleName = "新增部门信息";
      }
    },
    // //修改
    handleEdit(value,value1) {
      let res = this.perList.filter((val) => val == 38);
      console.log(res);
      if (!res.length) {
        Message.error("您没有这个权限");
      } else {
        console.log(value1,'value')
        this.dialogFormVisible = true;
        this.currentId = value;
        this.form.depname = value1.depname;
        this.form.procuratorateid2 = value1.procuratorateid;
        console.log(this.form.procuratorateid2,'procuratorateid2procuratorateid2')
        this.form.name = value1.name;
        console.log(this.form.name,'this.form.name ')
        this.titleName = "编辑部门信息";
      }
    },
    //点击确定
    handleClick() {
      var procuratorateid = ''
      if(this.adminid == 1){
        if (!this.form.procuratorateid2) {
          Message.error("请选择检察院名称");
          return;
        }
        procuratorateid = this.form.procuratorateid2
      }else{
        procuratorateid = this.form.procuratorateid
      }
      // else{
      //   var { depname,procuratorateid } = this.form;
      // }
      if (!this.form.depname) {
        Message.error("请输入部门信息！");
        return;
      }
      const id = this.currentId;

      
      if (!id) {
        // console.log(procuratorateid,depname,this.form.procuratorateid2,procuratorateid,'procuratorateid')
        insertLocation(this.form.depname,procuratorateid,this.adminid).then(async (data) => {
          if (data.code !== 200) {
            Message.error("操作失败！");
            return;
          }
          this.dialogFormVisible = false;
          Message.success("提交成功！");
          await this.getList();
        });
      } else {
        updateLocation(id, this.form.depname,procuratorateid).then(async (data) => {
          if (data.code !== 200) {
            Message.error("操作失败！");
            return;
          }
          this.dialogFormVisible = false;
          Message.success("提交成功！");
          await this.getList();
        });
      }
    },
    //搜索
    departSelect() {
      this.getList()
      // let name = this.procuratorateid;
    //   // console.log(departmentName);
    //   this.page = 1;
    //   dfuzzySearchLocation(name).then((data) => {
    //     console.log(data.data);
    //     if (data.data == null) {
    //       Message.warning("查询内容不存在！");
    //     } else {
    //       this.tableData = data.data;
    //       this.total = data.data.total;
    //       this.currentPage = data.data.pageNum;
    //     }
    //   });
    // },
    // //批量删除
    // open() {
    //   let arr = [];
    //   this.multipleSelection.forEach((item) => {
    //     arr.push(item.id);
    //   });
    //   if (arr.length ==0 ) {
    //     Message.warning("请选择批量删除的部门!");
    //     return;
    //   }
    //   this.$confirm("是否批量删除（该部门名下人员也将被删除，请提前更改人员信息）?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(() => {
    //       deleteDepartmentMore(arr).then(async () => {
    //         await this.getList();
    //       });
    //       Message.success("删除成功，请刷新页面!");
    //     })
    //     .catch(() => {
    //       Message.info("已取消删除!");
    //     });
    },
    // //批量表单
    handleSelectionChange(val) {
      // console.log(object)
      this.multipleSelection = val;
    },
    // //分页器
    handleCurrentChange(val) {
      this.page = val;
      if (this.selectTxt !== "") {
        this.departSelect();
        return;
      }
      this.getList();
    },
    closeDialog() {
      this.dialogFormVisible = false;
    },
  },
};
</script>
<style lang='less' >
.organization_warp {
  box-sizing: border-box;
  position: relative;
  width: auto;
  height: 92%;
  background: #fff;
  margin-top: 16px;
  padding: 20px 13px 0 13px;
  .main_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .style_button_Sel {
    margin-left: 10px;
  }
  .el-dialog {
    margin: 300px auto !important;
    width: 30% !important;
  }

  .el-dialog__header {
    border-bottom: 1px solid #e9e6e6;
  }
  .el-dialog__footer {
    border-top: 1px solid #e9e6e6;
  }
  .dialog_title {
    > img {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }
    > span {
      font-size: 13px;
      font-weight: 400;
      color: #2d8cf0;
    }
  }
  .form_label {
    > span {
      color: red;
      margin-right: 5px;
    }
  }
  .el-form-item__label {
    text-align: left;
    .form_label {
      font-size: 13px;
    }
  }
  .el-form-item .el-input__inner {
    width: 275px;
    height: 33px;
  }
  .el-dialog__body {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .style_button {
    width: 100px;
    height: 40px;
    background: #044bfe;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 300;
    color: #ffffff;
  }
  .style_button_del {
    background: #ff0012;
    margin-left: 13px;
  }
  .el-table td,
  .el-table th {
    text-align: center;
    padding: 8px 0;
  }
  .el-table {
    margin-top: 15px;
    .el-button {
      width: 80px;
      height: 36px;
      background: #3a8fea;
      font-size: 14px;
      color: #ffffff;
    }
  }
  .el-button--danger {
    background: #ff5200 !important;
  }
  .el-pagination {
    // position: absolute;
    // bottom: 34px;
    // left: 50%;
    // transform: translateX(-50%);
    text-align: center;
    margin-top: 15px;
  }
  .el-pager {
    > li {
      border: 1px solid #d2dae2;
      border-radius: 2px 2px 2px 2px;
      width: 28px !important;
      height: 28px !important;
      font-size: 12px;
      margin-right: 5px;
      min-width: 28px;
    }
    > li:last-child {
      margin-right: 0;
    }
    > li.active {
      box-sizing: border-box;
      background: #3a8fea;
      color: #fff;
      width: 28px !important;
      height: 28px !important;
    }
    li.active + li {
      border-left: 1px solid #d2dae2;
    }
  }
}
.el-pagination .btn-prev,
.el-pagination .btn-next {
  border: 1px solid #d2dae2;
  border-radius: 2px 2px 2px 2px;
  width: 28px !important;
  height: 28px !important;
  font-size: 12px;
  margin: 0 5px;
  min-width: 28px;
}
</style>